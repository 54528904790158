@import "https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap";
* {
  box-sizing: border-box;
}

body {
  all: unset;
  color: #222;
  background-color: #e4e4e4;
  font-family: Roboto Slab, serif;
  font-size: 12pt;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #e4e4e4;
    background-color: #222;
  }
}

header {
  height: 50pt;
  flex-direction: row;
  justify-content: end;
  display: flex;
}

.social {
  margin: 0;
  padding-top: 12.5pt;
  padding-bottom: 12.5pt;
  padding-right: 12.5pt;
}

.social img {
  width: 25pt;
  height: 25pt;
  margin: 0;
  padding: 0;
}

.social:hover img {
  transform: scale(1.1);
}

h1 {
  margin: 0;
  font-size: 30pt;
}

h2 {
  margin: 0;
  font-size: 25pt;
}

.name {
  height: 30pt;
  color: #0000;
  background-image: url("name.b74d87fb.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-bottom: 2pt dashed #aaa;
  font-family: Times New Roman, serif;
  display: inline-block;
  transform: translateY(1pt);
}

@media (prefers-color-scheme: dark) {
  .name {
    background-image: url("name-dark-bg.a0f79594.png");
  }
}

main {
  max-width: 520pt;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10pt;
  padding-right: 10pt;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
}

a, .link {
  all: unset;
  cursor: pointer;
  color: #0043de;
  text-decoration: underline;
}

a:hover, .link:hover {
  color: #235bdf;
}

a:active, .link:active {
  color: #4672d8;
}

@media (prefers-color-scheme: dark) {
  a, .link {
    color: #de7711;
  }

  a:hover, .link:hover {
    color: #df8932;
  }

  a:active, .link:active {
    color: #dd9a56;
  }
}

ul.links {
  padding-left: 0;
  list-style: none;
}

ul.links li {
  flex-direction: row;
  display: flex;
}

ul.links li > a {
  width: 40pt;
  height: 40pt;
  margin-right: 5pt;
}

ul.links li > a img {
  width: 40pt;
  height: 40pt;
}

ul.links li span {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5rem;
}

ul.links li:not(:first-child) {
  margin-top: 15pt;
}

footer {
  max-width: 520pt;
  color: #888;
  border-top: .5pt solid #888;
  margin-top: 30pt;
  margin-left: auto;
  margin-right: auto;
  padding: 10pt 10pt 30pt;
}

footer p {
  margin: 5pt 0;
  font-size: .8rem;
  line-height: 1rem;
}

/*# sourceMappingURL=index.7fc25975.css.map */
