@use "variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

* {
    box-sizing: border-box;
}

body {
    all: unset;
    background-color: #e4e4e4;
    color: #222222;
    font-size: 12pt;
    font-family: 'Roboto Slab', serif;

    @media (prefers-color-scheme: dark) {
        background-color: #222222;
        color: #e4e4e4;
    }
}

header {
    height: variables.$header-height;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.social {
    margin: 0;
    padding-top: calc((variables.$header-height - variables.$social-icon-size) / 2);
    padding-bottom: calc((variables.$header-height - variables.$social-icon-size) / 2);
    padding-right: calc(variables.$social-icon-size / 2);

    img {
        width: variables.$social-icon-size;
        height: variables.$social-icon-size;
        padding: 0;
        margin: 0;
    }

    &:hover img {
        transform: scale(1.1);
    }
}

h1 {
    font-size: 30pt;
    margin: 0;
}

h2 {
    font-size: 25pt;
    margin: 0;
}

.name {
    display: inline-block;
    height: 30pt;
    border-bottom: 2pt dashed #aaa;
    transform: translateY(1pt);
    color: transparent;
    background-image: url(images/name.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    font-family: 'Times New Roman', serif;

    @media (prefers-color-scheme: dark) {
        background-image: url(images/name-dark-bg.png);
    }
}

main {
    max-width: calc(variables.$doc-width + variables.$edge-margin * 2);
    margin-left: auto;
    margin-right: auto;
    padding-left: variables.$edge-margin;
    padding-right: variables.$edge-margin;
}

p {
    font-size: 1rem;
    line-height: 1.5rem;
}

a, .link {
    all: unset;
    cursor: pointer;
    color: #0043de;
    text-decoration: underline;

    &:hover {
        color: #235bdf;
    }

    &:active {
        color: #4672d8;
    }

    @media (prefers-color-scheme: dark) {
        color: #de7711;

        &:hover {
            color: #df8932;
        }

        &:active {
            color: #dd9a56;
        }
    }
}

ul.links {
    list-style: none;
    padding-left: 0;

    li {
        display: flex;
        flex-direction: row;

        & > a {
            img {
                width: variables.$proj-logo-size;
                height: variables.$proj-logo-size;
            }

            width: variables.$proj-logo-size;
            height: variables.$proj-logo-size;
            margin-right: 5pt;
        }

        span {
            flex: 1;
            font-size: 1rem;
            line-height: 1.5rem;
        }

        &:not(:first-child) {
            margin-top: 15pt;
        }
    }
}

footer {
    max-width: calc(variables.$doc-width + variables.$edge-margin * 2);
    margin-left: auto;
    margin-right: auto;
    padding-left: variables.$edge-margin;
    padding-right: variables.$edge-margin;

    margin-top: 30pt;
    border-top: 0.5pt solid #888888;
    padding-top: 10pt;

    padding-bottom: 30pt;

    color: #888888;

    p {
        font-size: 0.8rem;
        line-height: 1rem;
        margin: 5pt 0;
    }
}